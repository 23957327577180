* {
  --primary-bg: #faf8f6;
  --secondary-bg: #fe0000;
  --third-bg: #010606;
  --index-bg: #fffbf4;
  --primary-txt-color: #fe0000;
  --secondary-txt-color: #010606;
  --third-txt-color: #888888;
  --fourth-txt-color: #fffbf4;
  --inversed-txt-color: #faf8f6;
  --border-radius: 15px;
  --speed: 500m;

  font-family: "MyCase", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary-shadow: 0 0 #00000009, 0 0 #0000000c, 0 0 #0000000f, 0 0 #00000012,
    0 2px 5px #00000016, 0 10px 15px #0001;
}

html {
  font-size: 16px;
}
h1 {
  font-size: 3em;
  line-height: 1;
  /* margin-bottom: 0.5em; */
}
h2 {
  font-size: 2em;
  /* margin-bottom: 0.75em; */
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  /* margin-bottom: 1em; */
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  /* margin-bottom: 1.25em; */
}
h5 {
  font-size: 1em;
  font-weight: bold;
  /* margin-bottom: 1.5em; */
}
h6 {
  font-size: 1em;
  font-weight: bold;
}
/* p {
  margin: 0 0 1.5em;
} */

@font-face {
  font-family: "MyCase";
  src: url(https://www.dropbox.com/s/x3vr0gwwxw6oq0d/Larsseit-Thin.woff)
      format("woff"),
    url(https://www.dropbox.com/s/706rbr82spceygd/Larsseit-Thin.eot)
      format("eot");
  font-weight: 100;
}

@font-face {
  font-family: "MyCase";
  src: url(https://www.dropbox.com/s/kbo65aud6kagxu6/Larsseit-Light.woff)
      format("woff"),
    url(https://www.dropbox.com/s/szao9fw4rnont1k/Larsseit-Light.eot)
      format("eot");
  font-weight: 300;
}

@font-face {
  font-family: "MyCase";
  src: url(https://www.dropbox.com/s/kbo65aud6kagxu6/Larsseit-Light.woff)
      format("woff"),
    url(https://www.dropbox.com/s/t83q4r2aj75hm3t/Larsseit-Medium.eot)
      format("eot");
  font-weight: 500;
}

@font-face {
  font-family: "MyCase";
  src: url(https://www.dropbox.com/s/kt3hg6rnsra8hsn/Larsseit-Bold.woff)
      format("woff"),
    url(https://www.dropbox.com/s/ypwtyaso8ipky41/Larsseit-Bold.eot)
      format("eot");
  font-weight: 700;
}

@font-face {
  font-family: "MyCase";
  src: url(https://www.dropbox.com/s/sx98torjwv6r08l/Larsseit-ExtraBold.woff)
      format("woff"),
    url(https://www.dropbox.com/s/uhcfamx4szioaq2/Larsseit-ExtraBold.eot)
      format("eot");
  font-weight: 900;
}

@font-face {
  font-family: "GT";
  src: url(../src/fonts/GT-Ultra-Fine-Thin-Italic.woff) format("woff"),
    url(../src/fonts/GT-Ultra-Fine-Thin-Italic.eot) format("eot");
  font-weight: 300;
}

@font-face {
  font-family: "GT";
  src: url(../src/fonts//GT-Ultra-Fine-Regular-Italic.woff) format("woff"),
    url(../src/fonts/GT-Ultra-Fine-Regular-Italic.eot) format("eot");
  font-weight: 500;
}
