.cta-container {
  position: relative;
  z-index: 1000;
}

.visible {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9, 0.9);
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.unvisible {
  opacity: 0;
  position: fixed;
  top: 50%;
  left: -50%;
  transform: translate(50%, 50%) scale(0.9, 0.9);
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.cta-button {
  width: 240px;
  border-radius: 50px;
  border: solid 2px var(--third-bg);
  background: var(--third-bg);
  color: var(--inversed-txt-color);
  white-space: nowrap;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  filter: drop-shadow(0 1px 7px #0000001a) drop-shadow(0 2px 3px #00000014)
    drop-shadow(0 4px 7px #00000029);
  /* transition: 0.2s cubic-bezier(0.65, 0, 0.16, 1); */
}

.cta-button:active {
  /* transition: 0.2s cubic-bezier(0.65, 0, 0.16, 1); */
  border: solid 2px var(--secondary-bg);
  background-color: var(--secondary-bg);
  color: var(--primary-bg);
}

@media only screen and (max-width: 960px) {
  .cta-button {
    font-size: 12px;
  }
}
